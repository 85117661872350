import React from "react"

import { graphql } from "gatsby"
import { SEO } from "../components/Seo"
import { LocaleContext } from "../components/Layout/Layout"

// Styled components
import { LandingPageWrapper, LandingPageBody } from "./landingpage-styles"

// Prismic slices
import { Image, Text, Video } from "../slices"

const LandingPage = ({
  data: { prismicLandingPage },
  location,
  pageContext: { locale },
}) => {
  const lang = React.useContext(LocaleContext)

  const i18n = lang.i18n[lang.locale]

  const {
    data: { meta_title, meta_description, body },
  } = prismicLandingPage

  const slices = body.map((slice, index) => {
    switch (slice.slice_type) {
      // These are the API IDs of the slices
      case "text":
        return <Text key={index} data={slice} />
      case "image":
        return <Image key={index} data={slice} />
      case "video":
        return <Video key={index} data={slice} />
      default:
        return null
    }
  })

  return (
    <>
      <SEO
        title={`${meta_title} | ${i18n.defaultTitleAlt}`}
        pathname={location.pathname}
        locale={locale}
        desc={meta_description}
        node={prismicLandingPage}
      />
      <LandingPageWrapper>
        <LandingPageBody>{slices && slices}</LandingPageBody>
      </LandingPageWrapper>
    </>
  )
}

export default LandingPage

export const pageQuery = graphql`
  query PageBySlug($uid: String!, $locale: String!) {
    prismicLandingPage(uid: { eq: $uid }, lang: { eq: $locale }) {
      data {
        meta_title
        meta_description
        body {
          ... on PrismicLandingPageBodyText {
            slice_type
            primary {
              text {
                html
              }
            }
          }
        }
      }
    }
  }
`
