import styled from "styled-components"

export const LandingPageWrapper = styled.div`
  margin-top: 150px;
  width: 100vw;
  min-height: 100vh;

  & > h1 {
    text-align: center;
  }

  @media (min-width: 800px) {
    margin-top: 200px;
  }
`

export const LandingPageBody = styled.div`
  & > h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    letter-spacing: 0px;
    text-transform: uppercase;
  }

  & ul {
    list-style-type: disc;
    font-size: 1rem;
  }

  & > * + * {
    margin-top: 40px;
    margin-bottom: 40px;

    @media (min-width: 800px) {
      margin-top: 150px;
      margin-bottom: 100px;
    }
  }

  @media (min-width: 800px) {
    margin-top: 40px;
  }
`
